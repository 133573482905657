.ConstPanel {
  display: block;
  position: relative;
  width: 380px;
  height: 350px;
  margin: 5px 5px 5px 5px;
  padding: 0px;
  border: 0px solid gray;
  background-color: white;
  text-align: inherit;
}

.ConstPanel:hover {
  background-color: #efe;
}

.ConstPanel .NoImage {
  position: absolute;
  z-index: 1;
  top: 80px;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: lightgray;
  text-align: center;
}

.ConstPanel .ConstImage {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  z-index: 0;
  background-color: #0000;
  top: 0;
  height: 200px;
  display: block;
  border: 0px solid gray;
  background-color: #888;
}

.ConstPanel .ConstId {
  font-size: 14px;
  margin-left: 10px;
  color: gray;
  font-weight: bold;
}

.ConstPanel .ConstName {
  font-size: 18px;
  margin-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #666;
}

.ConstPanel .ConstTerm {
  font-size: 13px;
  text-align: center;
  color: gray;
}

.ConstPanel .SalesStaff {
  width: 50%;
  display: inline-block;
}

.ConstPanel .SalesStaff .Label {
  text-align: left;
  margin-left: 10px;
  font-size: 11px;
  color: gray;
}

.ConstPanel .SalesStaff .Name {
  text-align: left;
  font-size: 16px;
  margin-left: 20px;
  font-weight: bold;
  color: gray;
}

.ConstPanel .ConstructionStaff {
  width: 50%;
  display: inline-block;
}

.ConstPanel .ConstructionStaff .Label {
  text-align: left;
  margin-left: 10px;
  font-size: 11px;
  color: gray;
}

.ConstPanel .ConstructionStaff .Name {
  text-align: left;
  font-size: 16px;
  margin-left: 20px;
  font-weight: bold;
  color: gray;
}

.ConstPanel .Process {
  position: absolute;
  top: 10px;
  right: 10px;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {
  .ConstPanel {
    display: block;
    position: relative;
    width: 120px;
    height: 70px;
    margin: 5px 5px 5px 5px;
    padding: 0px;
    border: 0px solid gray;
    background-color: white;
    text-align: inherit;
  }

  .ConstPanel .ConstImage {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 120px;
    z-index: 0;
    background-color: #0000;
    top: 0;
    height: 70px;
    display: block;
    border: 0px solid gray;
    background-color: #888;
  }

  .ConstPanel .ConstId {
    position: absolute;
    font-size: 13px;
    margin-left: 0;
    font-weight: bold;
    top: 52px;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .ConstPanel .ConstStatus{
    display:none;
  }
}