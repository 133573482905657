@media screen and (max-width: 480px) and (orientation:portrait) {
  .ConstListSelector .termFrom {
    display: none;
  }

  .ConstListSelector .salesStaff {
    display: none;
  }

  .ConstListSelector .staff {
    display: none;
  }

  .ConstListSelector {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    overscroll-behavior: none;
  }
}