.Tasks {
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
    display: block;
    margin: 5px;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .Tasks .ButtonLabel {
        display: none;
    }
}