.ConstReports {
  position: absolute;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  padding: 5px;
  background-color: white;
}

.ConstReports .Calendar {
  overflow: hidden;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  flex-grow: 0;
}

.ConstReports .Reports {
  position: relative;
  overflow-y: scroll;
  flex-grow: 1;
  display: block;
  max-width: 960px;
  min-width: 960px;
  padding-right: 20px;
  height: 100%;
}

.ConstReports .UnavailableDate {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: white;
}

.ConstReports .react-calendar__month-view__days__day--weekend {
  color: red;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .ConstReports {
    flex-direction: column;
    padding: 0px;
  }

  .ConstReports .Calendar {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 100%;
    justify-content: center;
    overflow: hidden;
  }

  .ConstReports .DateChoice {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
  }

  .ConstReports .DateChoice .Button {
    display: flex;
    text-align: center;
  }

  .ConstReports .Reports {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  .ConstReports .Reports .ConstReportRow {
    position: relative;
    display: block;
    padding: 0px;
    margin: 0px;
  }
}