.ConstReportDiscussion {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.ConstReportDiscussion .submitDate {
    padding-top: 5px;
    text-align: right;
    align-self: flex-end;
}

.ConstReportDiscussion .list {
    overflow-y: scroll;
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ConstReportDiscussion .outlinePanel {
    margin: 5px;
    position: relative;
    width: auto;
    height: auto;
    background-color: white;
}

.ConstReportDiscussion .paper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    overflow: hidden;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .ConstReportDiscussion .submitDate {
        align-self: flex-start;
    }

    .ConstReportDiscussion .paper {
        padding: 0px;
        display: block;
        position: relative;
        width: 100%;
    }

    .ConstReportDiscussion .ConstReport2 {
        width: calc(100% - 10px);
    }
}