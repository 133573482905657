.WorkReportList {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.WorkReportList .list {
    overflow-y: auto;
    overflow-x: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    height: "inherit";
}

.WorkReportList .submitDate {
    display: flex;
    width: 100%;
    padding-top: 5px;
    text-align: right;
    justify-content: flex-end;
}

.WorkReportList .outlinePanel {
    padding: 5px;
    position: relative;
    height: auto;
    background-color: white;
}

.WorkReportList .WorkReportHistory {
    width: calc(100% - 10px)
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .WorkReportList .list {
        overflow-x: hidden;
    }

    .WorkReportList .submitDate {
        justify-content: flex-start;
    }

    .WorkReportList .list {
        overflow-x: hidden;
    }

    .WorkReportList .outlinePanel {
        padding: 0px;
    }
}