.ConstInformations {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-wrap: wrap;
  width: 1200px;
}

.ConstInformations .Panel {
  background-color: #eee;
  margin: 10px;
}

.ConstInformations .ProjectOverview {
  width: 40%;
  min-width: 400px;
}

.ConstInformations .ProjectLocation {
  width: 35%;
  min-width: 300px;
}

.ConstInformations .Weather {
  width: 35%;
  min-width: 300px;
}

.ConstInformations .ProjectProgress {
  width: 25%;
  min-width: 100px;
}

.ConstInformations .ProjectProgress .DisplayProgress {
  display: flex;
  padding: 30px 0px 0px 0px;
  width: 100%;
  justify-content: center;
}

.ConstInformations .ProjectStatus {
  width: 100%;
  min-width: 600px;
}

.ConstInformations .ProjectStatus {
  width: 100%;
}

.ConstInformations .ProjectPhotos {
  width: 30%;
  flex-grow: 1;
  min-width: 300px;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation: portrait) {
  .ConstInformations {
    width: 100%;
  }

  .ConstInformations .Panel {
    margin: 5px;
  }

  .ConstInformations .ProjectOverview {
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
    overflow: hidden;
  }

  .ConstInformations .ProjectLocation {
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }

  .ConstInformations .Weather {
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }

  .ConstInformations .ProjectProgress {
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }

  .ConstInformations .ProjectProgress .DisplayProgress {
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    padding: 0px 0px 0px 0px;
  }

  .ConstInformations .ProjectStatus {
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }

  .ConstInformations .ProjectPhotos {
    width: calc(100% - 10px);
    min-width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }
}