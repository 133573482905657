.ConstManageChoicer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  display: block;
  left: 50%;
  right: -50%;
  background-color: white;
}

.ConstManageChoicer .ConstImageContainer {
  position: relative;
  display: block;
  width: 150px;
  height: 100px;
}

.ConstManageChoicer .TabHeader {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  border-bottom: 1px solid #ccc;
}

.ConstManageChoicer .TabContent {
  position: absolute;
  top: 150px;
  bottom: 0px;
  width: 100%;
  display: block;
  overflow-x: auto;
  overflow-y: auto;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation: portrait) {
  .ConstManageChoicer .TabHeader {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .ConstManageChoicer .TabContent {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }
}