.MainContent {
  position: absolute;
  background-color: #a9a9a9;
  top: 40px;
  bottom: 56px;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
  .MainContent {
    position: fixed;
    overscroll-behavior: none;
  }
}