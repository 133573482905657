.ConstListGrid {
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation: portrait) {
  .ConstListGrid {
    margin-left: 13px;
  }
}