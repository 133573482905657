.ConstListController {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 100px);
  min-height: calc(100% - 100px);
  min-height: calc(100% - 100px);
}

.ConstListController .constHeader {
  width: 100%;
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation:portrait) {}

.ResultManageFooter a:-webkit-any-link {
  color: white;
  text-decoration: none;
}