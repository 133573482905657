.Footer {
    background-color: gray;
    position: absolute;
    display: flex;
    bottom: 0;
    height: 56px;
    left: 0;
    right: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
}

@media screen and (max-width: 480px) and (orientation:portrait) {
    .Footer {
        position: fixed;
    }
}