.ConstPanelForSelected {
  display: flex;
  position: relative;
  width: 550px;
  min-width: 550px;
  max-width: 550px;
  margin: 5px 5px 5px 5px;
  padding: 0px;
  width: initial;
  height: initial;
  border: 0px solid gray;
  background-color: white;
  text-align: inherit;
  border-bottom: 1px solid gray;
  padding: 2px;
}

.ConstPanelForSelected .NoImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 100px;
  font-size: 18px;
  font-weight: bold;
  color: lightgray;
  text-align: center;
  vertical-align: middle;
}

.ConstPanelForSelected .ConstImage {
  border-radius: 4px;
  top: 0;
  left: 0;
  width: 150px;
  height: 100px;
  display: block;
  border: 0px solid gray;
  background-color: #888;
}

.ConstPanelForSelected .ConstId {
  font-size: 14px;
  margin-left: 10px;
  color: gray;
  font-weight: bold;
}

.ConstPanelForSelected .ConstName {
  font-size: 18px;
  margin-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #666;
}

.ConstPanelForSelected .ConstTerm {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-align: center;
  color: gray;
}

.ConstPanelForSelected .ConstTerm input {
  width: 100px;
}

.ConstPanelForSelected .SalesStaff {
  width: 50%;
  display: inline-block;
}

.ConstPanelForSelected .SalesStaff .Label {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
  font-size: 11px;
  color: gray;
}

.ConstPanelForSelected .SalesStaff .Name {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  margin-left: 20px;
  font-weight: bold;
  color: gray;
}

.ConstPanelForSelected .ConstructionStaff {
  width: 50%;
  display: inline-block;
}

.ConstPanelForSelected .ConstructionStaff .Label {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
  font-size: 11px;
  color: gray;
}

.ConstPanelForSelected .ConstructionStaff .Name {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  margin-left: 20px;
  font-weight: bold;
  color: gray;
}

.ConstPanelForSelected .Process {
  position: absolute;
  top: 80px;
  left: 100px;
  right: initial;
  font-size: 8px;
}

/*
* スマホ用定義
*/
@media screen and (max-width: 480px) and (orientation: portrait) {
  .ConstPanelForSelected {
    position: relative;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    height: 80px;
    margin: 5px 5px 5px 5px;
    padding: 0px;
    border: 0px solid gray;
    background-color: white;
    text-align: inherit;
  }

  .ConstPanelForSelected .ConstImage {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 120px;
    height: 70px;
    z-index: 0;
    background-color: #0000;
    top: 0;
    display: block;
    border: 0px solid gray;
    background-color: #888;
  }

  .ConstPanelForSelected .ConstId {
    font-size: 13px;
    font-weight: bold;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .ConstPanelForSelected .ConstName {
    font-size: 15px;
    z-index: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .ConstPanelForSelected .ConstTerm {
    display: none;
  }

  .ConstPanelForSelected .SalesStaff {
    width: 120px;
  }

  .ConstPanelForSelected .ConstructionStaff {
    width: 120px;
  }

  .ConstPanelForSelected .SalesStaff .Label {
    display: block;
  }

  .ConstPanelForSelected .ConstructionStaff .Label {
    display: block;
  }

  .ConstPanelForSelected .ConstStatus {
    display: none;
  }
}